import { Typography, Grid } from "@mui/material"

const IndexPage = () => (
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
    style={{ minHeight: "100vh" }}
  >
    <Grid item xs={3}>
      <Typography variant="h3" component="h1" align="center" spacing="1">
        #speakerliberationfront
      </Typography>{" "}
    </Grid>
  </Grid>
)

export default IndexPage
